$mobile-max: 992px;
$desktop-min: 992px;

// Buttons
a.wsite-button:not(.wsite-buy-button),
a.wsite-button.wsite-button-large:not(.wsite-buy-button),
a.wsite-button.wsite-button-highlight:not(.wsite-buy-button) {
  &:hover {
    background-color: black!important;
    color: white!important;
    border: 0!important;
  }
  span.wsite-button-inner {
    white-space: inherit!important;
  }
}




// Logo
div.logo {
  span.wsite-logo {
    a {
      img {
        @media (min-width: $desktop-min) {
          max-width: 205px;
          max-height: unset;
          margin: auto;
        }
      }
    }
  }
}


//Header
body:not(.front) {
  div.wrapper {
    div.birdseye-header {
      background-color: black;
      padding-top: 15px!important;
      padding-bottom: 15px!important;
      @media (min-width: $desktop-min) {
        padding-top: 40px!important;
        padding-bottom: 40px!important;
      }
    }

  }
}


// Navigation
a.hamburger {
  span,
  span::before,
  span::after {
    background-color: white!important;
  }
}
body.nav-open {
  .nav.mobile-nav a.hamburger {
    span::before,
    span::after {
      background-color: black!important;
    }
  }
}
body.affix {
  .birdseye-header {
    position: absolute!important;
  }
}

div.nav.desktop-nav {
  li {
    color: white;
  }
}


#wsite-menus .wsite-menu {
  @media (min-width: $desktop-min) {
    background-color: black!important;
  }
   li {
     a {
       text-transform: capitalize!important;
       @media (min-width: $desktop-min) {
         color: white!important;

       }
     }
   }
}
